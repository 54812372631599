import { useEffect, useState } from 'react';
import {
  Flex,
  Box,
  Button,
  Text,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  List,
  ListItem,
  // Heading,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useAppointmentActions } from '../hooks';
import { currentUserState } from '../state';
import Hero from '../components/generic/Hero';
import { Appointment, Appointments } from '../types';
// import { useTherapistActions } from '../hooks/therapist-actions';

// import { TherapistCard } from '../components/therapists/TherapistCard';
import { Footer } from '../components/layout';
import { NextAvailableAppointmentCard } from '../components/appointments/NextAvailableAppointment';
import TopicsList from '../components/topics/TopicsList';
import { TopBar } from '../layouts';
import { BankIdModal } from '../components/bankid/BankIdModal';
import { SoftCard } from '../components/generic/SoftCard';
import NewsLetter from '../components/newsletter/NewsLetter';

export const SignInPage: React.FC = () => {
  const user = useRecoilValue(currentUserState);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const { getAppointments, previewAppointmentById } = useAppointmentActions();

  // const { getTherapists } = useTherapistActions();

  const [showModal, setShowModal] = useState(false);
  // const [therapists, setTherapists] = useState<Therapist[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [appointments, setAppointments] = useState<Appointments>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [previewedAppointment, setPreviewedAppointment] = useState<Appointment | null>(null);

  const doIt = async () => {
    const allAppointments = await getAppointments();
    if (allAppointments) {
      setAppointments(allAppointments);
      if (allAppointments.length > 0) {
        const nextUpcomingAppointment = await previewAppointmentById(allAppointments[0].appointments[0].id);
        if (nextUpcomingAppointment) {
          setPreviewedAppointment(nextUpcomingAppointment);
        }
      }
    }
    // const allTherapists = await getTherapists();
    // if (allTherapists) {
    //   setTherapists(allTherapists);
    // }
  };

  useEffect(() => {
    doIt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBookingClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <BankIdModal setShowModal={setShowModal} showModal={showModal}></BankIdModal>
      <TopBar
        handleClick={() => {
          setShowModal(true);
        }}
      />
      <Hero imageSrc={`/background.jpeg`} headingText="Digital vård kring din sexuella hälsa">
        <Box p={4}>
          {/* <Heading pt="12" textColor="white" fontSize="lg">
            För dig som har frågor/besvär/problem rörande din sexuella hälsa
          </Heading> */}
          <NextAvailableAppointmentCard loggedIn={false} handleBook={handleBookingClick} />
        </Box>
      </Hero>
      <Flex
        // backgroundColor="#ECE9FB"
        flexDirection="column"
        height="100vh"
        // gap="24"
        p={
          {
            // base: 4,
            // sm: 8,
          }
        }
        w={'100%'}
      >
        <Box backgroundColor="#ECE9FB" py={24} w="full">
          <Box mx="auto" maxW="1024px">
            <Text textColor="#24084F" textAlign="center" pb="2" fontSize="4xl" id="whatCanWeDo">
              Vad kan du få hjälp med?
            </Text>
            <Box
              p={{
                base: 4,
                sm: 8,
              }}
            >
              <TopicsList />
            </Box>
          </Box>
        </Box>
        <Box px={8} py={24} borderWidth="1px" borderRadius="lg">
          <Text textAlign="center" textColor="#24084F" pb={6} fontSize="4xl" id="ourTherapists">
            Våra sexologer
          </Text>
          <Box mx="auto" maxW="1024" display={{ md: 'flex' }} alignItems="center">
            <Box textColor="#24084F" h="full" pr={{ md: 8 }} w={{ md: '50%' }} mt={5}>
              <Text>
                Våra sexologer är här för dig och erbjuder professionell rådgivning och stöd för dina sexuella besvär.
              </Text>
              <Text my={4}>
                Utforska vårt team genom att logga in och påbörja din resa mot en bättre sexuell hälsa.
              </Text>

              {/* <Button
                onClick={() => {
                  setShowModal(true);
                }}
                textColor="white"
                backgroundColor={'#5E13D8'}
                py={6}
                px={12}
                colorScheme={'purple'}
                fontWeight={'light'}
              > */}

              {/* 
              <Button
                onClick={() => {
                  setShowModal(true);
                }}
                textColor="white"
                backgroundColor={'#5E13D8'}
                p={8}
                colorScheme={'purple'}
                w="full"
              >
                Logga in och boka tid
              </Button> */}
            </Box>

            <Box
              mt={{ base: 8, md: 0 }}
              display="flex"
              w={{ base: '100%', md: '50%' }}
              position="relative"
              justifyContent={{ base: 'center', md: 'start' }}
              transform={{ base: 'translateX(-100px)', md: 'none' }}
            >
              <Image
                border="3px solid white"
                boxSize={{ base: '150px', md: '198px' }}
                borderRadius="full"
                src="/therapists/therapist-c2f31b10-b7db-44b2-9891-8e29ed3f834d.jpeg"
                alt="First person"
                objectFit="cover"
              />
              <Image
                boxSize={{ base: '150px', md: '198px' }}
                border="3px solid white"
                borderRadius="full"
                src="/therapists/therapist-b9a8f538-7c22-4b13-b073-7d06f9b22b37.jpeg"
                alt="Second person"
                objectFit="cover"
                position="absolute"
                transform={'translateX(100px)'}
              />
              <Image
                boxSize={{ base: '150px', md: '198px' }}
                border="3px solid white"
                borderRadius="full"
                alt="Third person"
                objectFit="cover"
                position="absolute"
                src="/therapists/therapist-18257aac-210e-46e5-9c25-7844cb2b9986.jpeg"
                transform={'translateX(200px)'}
              />
            </Box>
          </Box>
          <Box w={'full'} display={'flex'} justifyContent={'center'} pt={12}>
            <Button
              justifyContent={'center'}
              display="flex"
              gap={2}
              py={6}
              px={12}
              // width={'100%'}
              borderRadius={'8px'}
              border="2px solid #ECE9FB"
              backgroundColor={'#F6F5FF'}
              color={'#5E13D8'}
              _hover={{
                backgroundColor: '#ECE9FB',
                border: '2px solid #E0DCF9',
                color: '#5112B0',
              }}
              _active={{
                backgroundColor: '#E0DCF9',
                border: '2px solid #E0DCF9',
                color: '#420F94',
              }}
              fontWeight="semibold"
              textColor="#5E13D8"
              alignItems={'center'}
              role="group"
              // border="1px"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Logga in och se alla sexologer
            </Button>
          </Box>
        </Box>

        {/* <Box
          borderBottom={'2px solid #E0DCF9'}
          borderTop={'2px solid #E0DCF9'}
          backgroundColor="#ECE9FB"
          pt="12"
          pb="20"
        >
          <Text textAlign="center" pb="2" fontSize="4xl" id="ourTherapists">
            Våra sexologer
          </Text>
          <SimpleGrid
            px="4"
            mx="auto"
            maxW="1024px"
            minChildWidth="150px"
            width="100%"
            spacingX="20px"
            mt={8}
            spacingY="20px"
          >
            {therapists?.map((therapist) => (
              <TherapistCard notLoggedIn={true} key={therapist.id} therapist={therapist} />
            ))}
          </SimpleGrid>
        </Box> */}

        <Box backgroundColor="#ECE9FB" py={24} id="howItWorks" borderBottom={'2px solid #E0DCF9'}>
          <Box display={{ md: 'flex' }} w="100%" mx="auto" maxW="1024px">
            <Box mx={'auto'} w={{ base: '95%', md: '50%' }}>
              <Image borderRadius={'24px'} width="100%" height="100%" objectFit="cover" src="/how-it-works.jpg" />
            </Box>
            <Box mx="auto" px={{ base: '4', md: '12' }} pt={12}>
              <Text textColor="#24084F" textAlign="center" pb="6" fontSize="4xl">
                Så funkar det
              </Text>
              <List spacing="6">
                <ListItem gap="6" display="flex" alignItems="center">
                  <Box w={6}>
                    <Text textColor="#24084F" fontSize="4xl">
                      1
                    </Text>
                  </Box>
                  <Box>
                    <Text textColor="#24084F" fontSize="xl">
                      Du bokar en tid
                    </Text>
                    <Text textColor="#24084F">Välj en tidpunkt som passar dig, dag/kväll/helg</Text>
                  </Box>
                </ListItem>

                <ListItem gap="6" display="flex" alignItems="center">
                  <Box w={6}>
                    <Text textColor="#24084F" fontSize="4xl">
                      2
                    </Text>
                  </Box>
                  <Box>
                    <Text textColor="#24084F" fontSize="xl">
                      Digitalt möte sker i CharlieCares plattform
                    </Text>
                    <Text textColor="#24084F">
                      Välj en plats som känns trygg för dig och logga in i god tid före ditt besök
                    </Text>
                  </Box>
                </ListItem>
                <ListItem gap="6" display="flex" alignItems="center">
                  <Box w={6}>
                    <Text textColor="#24084F" fontSize="4xl">
                      3
                    </Text>
                  </Box>
                  <Box>
                    <Text textColor="#24084F" fontSize="xl">
                      Rådgivningssamtal
                    </Text>
                    <Text textColor="#24084F">Under besöket får du rådgivning av sexolog kring din sexuella hälsa</Text>
                  </Box>
                </ListItem>
                {/* <ListItem gap="6" display="flex" alignItems="center">
                  <Box w={6}>
                    <Text fontSize="4xl">4</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xl">Uppföljande samtal</Text>
                    <Text>Vid behov och i samråd med sexolog</Text>
                  </Box>
                </ListItem> */}
                <ListItem>
                  <Button
                    onClick={() => {
                      setShowModal(true);
                    }}
                    textColor="white"
                    backgroundColor={'#5E13D8'}
                    w="full"
                    py={6}
                    colorScheme={'purple'}
                    fontWeight={'light'}
                  >
                    Logga in och boka tid
                  </Button>
                </ListItem>
              </List>
              <Text textColor="#24084F" textAlign="center" pt="6" fontSize="xl" id="faq">
                CharlieCare — När sex känns svårt!
              </Text>
            </Box>
          </Box>
        </Box>
        <Box px={4} py={24}>
          <Text textColor="#24084F" textAlign="center" pb="12" fontSize="4xl" id="faq">
            Vanliga frågor — FAQ
          </Text>

          <SoftCard maxW="1024" mx="auto">
            <Accordion allowToggle>
              <AccordionItem borderTop="0px solid">
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Hur bokar jag en tid?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  För att boka en tid börjar du med att logga in. Efter det väljer du en ledig tid direkt, eller så
                  väljer du en ledig tid i sexologens tidbok. Du fyller i dina kontaktuppgifter och väljer
                  betalningsmetod för patientavgiften; Kort eller Klarna.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Hur loggar jag in inför mitt bokade besök?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Inför ditt bokade besök loggar du in genom att klicka på “Logga in med BankId" som du hittar högst upp
                  till höger. Du identifierar dig med bank-ID. Här ser du dina inbokade mötestider. Välj det möte som du
                  nu är inbokad till. Då du klickar på mötet kommer du automatiskt in på mötet.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Hur mycket kostar ett besök?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Du betalar 200 kronor i patientavgift. För parsamtal betalar ni 400 kronor vilket avser varsin
                  patientavgift på 200 kronor. Besöket är kostnadsfritt om du har frikort.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Skriver ni journal?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Vi skriver journal. Journalen är ett viktigt arbetsinstrument och stöd i vården och behandlingen.
                  Journalen finns tillgänglig för andra vårdgivare, men är skyddad av sekretess. Du kan även välja att
                  spärra din journal så att den inte finns tillgänglig för andra vårdgivare.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Får jag prata med samma sexolog vid mitt nästa samtal?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Ja, du får prata med samma sexolog vid nästa tillfälle. Du har också möjlighet att byta sexolog om du
                  önskar.
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </SoftCard>
        </Box>
        <Box backgroundColor="#ECE9FB">
          <Box w="full" maxW={'1024px'} mx="auto" py={24} px={{ base: 4, md: '0' }}>
            <Text textColor="#24084F" textAlign="center" pb="12" fontSize="4xl" id="newsletter">
              Nyhetsbrev
            </Text>
            <NewsLetter />
          </Box>
        </Box>

        <Footer show></Footer>
      </Flex>
    </>
  );
};
