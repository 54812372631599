import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';

interface HeroProps {
  imageSrc: string;
  headingText: string;
  children: React.ReactNode;
}

export const Hero: React.FC<HeroProps> = ({ imageSrc, headingText, children }) => {
  return (
    <Flex justify="center" align="center" w="100%" h="100vh" maxH="700px" position="relative">
      <Box
        w="100%"
        h="100%"
        position="absolute"
        top="0"
        bgImage={`url(${imageSrc})`}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="center"
        _after={{
          content: `""`,
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          bg: 'blackAlpha.600',
        }}
      />
      <Box maxW={{ base: 'lg', md: '2xl' }} mx="auto" position="relative" zIndex="1">
        <Box display="flex" justifyContent={'center'} pb={4}>
          <svg width="126" height="75" viewBox="0 0 323 192" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_114_2363)">
              <path
                d="M229.692 59.1446C229.096 59.0254 220.972 57.4768 210.274 58.6681C210.203 48.3517 207.94 40.9659 207.82 40.6085L206.701 37.0824L203.079 37.7733C202.579 37.8686 192.334 39.8937 181.351 46.7078C175.156 30.6972 164.625 22.811 163.958 22.3345L161.504 20.5476L159.05 22.3345C158.407 22.811 147.852 30.6972 141.658 46.7078C130.698 39.9176 120.429 37.8686 119.953 37.7733L116.331 37.0824L115.235 40.6085C115.116 40.9659 112.853 48.3279 112.781 58.6681C102.084 57.4768 93.9594 59.0254 93.3637 59.1446L89.504 59.907L90.052 63.7905C90.1473 64.3861 92.2439 78.3954 102.084 92.5C111.209 105.604 128.935 121.209 161.504 121.209C194.073 121.209 211.799 105.604 220.924 92.5C230.764 78.3954 232.861 64.3861 232.932 63.7905L233.48 59.907L229.621 59.1446H229.692ZM200.816 46.946C201.411 49.9957 202.055 54.6416 201.983 60.0976C196.67 61.3603 191.071 63.4331 185.663 66.6734C185.401 62.4563 184.781 58.5251 183.924 54.9275C190.38 50.5913 196.861 48.1611 200.816 46.946ZM122.264 46.946C126.195 48.1611 132.675 50.5675 139.156 54.9275C138.298 58.5251 137.679 62.4563 137.417 66.6734C132.008 63.4331 126.409 61.3603 121.096 60.0976C121.025 54.6416 121.668 49.9718 122.264 46.9222V46.946ZM109.112 88.0446C103.347 79.8487 100.511 71.5575 99.2247 66.7687C106.682 66.173 122.645 66.2445 135.94 75.6078C146.947 83.3272 153.999 95.8593 156.977 112.847C136.059 111.798 119.953 103.483 109.088 88.0685L109.112 88.0446ZM145.589 72.7012C145.112 49.5906 156.405 36.0817 161.552 31.1737C166.698 36.1055 177.991 49.5906 177.515 72.7012C170.462 79.0387 165.125 87.306 161.552 97.408C157.978 87.306 152.641 79.0387 145.589 72.7012ZM213.991 88.0446C203.127 103.46 187.021 111.798 166.102 112.823C169.08 95.8355 176.157 83.3272 187.14 75.584C197.838 68.079 210.227 66.5304 218.542 66.5304C220.591 66.5304 222.378 66.6257 223.855 66.7448C222.568 71.5575 219.733 79.8487 213.967 88.0208L213.991 88.0446Z"
                fill="#fff"
              />
            </g>
            <path
              d="M23.304 190.772C18.8959 190.772 15.0465 189.778 11.7146 187.812C8.38264 185.846 5.81642 183.052 3.95384 179.431C2.09126 175.809 1.18066 171.566 1.18066 166.661C1.18066 163.123 1.71874 159.894 2.8156 156.935C3.91245 153.975 5.4439 151.409 7.43065 149.236C9.41741 147.063 11.7767 145.387 14.4671 144.207C17.1782 143.027 20.1376 142.427 23.3661 142.427C26.3048 142.427 28.871 142.8 31.0647 143.524C33.2584 144.248 35.2038 145.304 36.9215 146.67L34.6864 155.217C33.3826 153.892 31.8098 152.899 29.9886 152.237C28.1674 151.575 26.1599 151.243 23.9662 151.243C21.7725 151.243 19.8272 151.595 18.0888 152.32C16.3503 153.044 14.881 154.058 13.66 155.403C12.4389 156.748 11.5076 158.342 10.8661 160.225C10.2245 162.109 9.8934 164.219 9.8934 166.579C9.8934 169.807 10.4729 172.56 11.6318 174.857C12.7907 177.154 14.4464 178.913 16.578 180.113C18.7096 181.334 21.2551 181.935 24.2146 181.935C26.3255 181.935 28.3743 181.604 30.4025 180.921C32.4306 180.238 34.3139 179.161 36.0937 177.692L38.0597 186.26C36.1558 187.688 33.9828 188.806 31.5407 189.592C29.0987 190.378 26.3462 190.772 23.304 190.772Z"
              fill="#fff"
            />
            <path
              d="M71.8137 168.379V189.758H63.639V170.925C63.639 168.773 63.0389 167.034 61.8385 165.71C60.6589 164.365 59.024 163.702 56.9751 163.702C55.5885 163.682 54.3882 163.992 53.3535 164.634C52.3394 165.275 51.5323 166.186 50.9735 167.386C50.394 168.566 50.125 169.952 50.125 171.546V189.758H41.9089V144.104L50.125 141.455V159.418C51.0563 158.466 52.091 157.68 53.2086 157.059C55.0712 156.045 57.0579 155.528 59.2102 155.528C62.0248 155.528 64.3427 156.128 66.226 157.37C68.0885 158.591 69.4958 160.184 70.4271 162.15C71.3377 164.116 71.8137 166.186 71.8137 168.379Z"
              fill="#fff"
            />
            <path
              d="M101.988 161.177C100.829 159.149 99.2144 157.659 97.1035 156.666C95.0133 155.693 92.5505 155.197 89.7153 155.197C87.7699 155.197 85.7832 155.466 83.755 156.024C81.7062 156.583 79.7401 157.473 77.8775 158.735L78.9951 165.42C80.4024 164.365 81.9338 163.578 83.5688 163.061C85.2037 162.543 86.8386 162.274 88.4528 162.274C90.1706 162.274 91.5572 162.564 92.654 163.102C93.7509 163.661 94.558 164.447 95.0754 165.441C95.572 166.455 95.8411 167.634 95.8411 169V169.476C92.7161 169.228 90.005 169.249 87.6664 169.538C84.9346 169.89 82.6788 170.532 80.9197 171.484C79.1399 172.436 77.8361 173.657 76.9876 175.126C76.1391 176.616 75.7252 178.272 75.7252 180.134C75.7252 182.307 76.1805 184.191 77.0911 185.784C78.0017 187.378 79.2434 188.599 80.8577 189.468C82.4719 190.317 84.2931 190.751 86.3626 190.751C88.577 190.751 90.5638 190.358 92.3436 189.53C94.1027 188.723 95.4893 187.543 96.5033 186.012V189.758H103.726V168.876C103.726 165.751 103.147 163.185 101.988 161.177ZM95.8411 177.216C95.8411 178.644 95.51 179.865 94.8684 180.879C94.2268 181.914 93.3369 182.68 92.2401 183.218C91.1225 183.756 89.8808 184.025 88.5149 184.025C86.88 184.025 85.6176 183.653 84.6656 182.908C83.7343 182.163 83.2583 181.086 83.2583 179.7C83.2583 178.748 83.5688 177.92 84.1482 177.216C84.7484 176.513 85.6383 175.954 86.8179 175.561C87.9976 175.168 89.4876 174.94 91.2467 174.919C92.5919 174.878 94.1441 174.981 95.8411 175.23V177.216Z"
              fill="#fff"
            />
            <path
              d="M108.465 189.778V158.363L116.681 155.755V160.826C117.716 158.901 118.999 157.535 120.572 156.728C122.145 155.921 123.821 155.528 125.622 155.528C126.139 155.528 126.657 155.569 127.153 155.631C127.65 155.693 128.126 155.755 128.561 155.838L128.043 165.172C127.422 164.923 126.76 164.737 126.056 164.613C125.353 164.489 124.67 164.406 123.987 164.406C121.876 164.406 120.117 165.089 118.751 166.475C117.385 167.862 116.681 169.787 116.681 172.291V189.799H108.465V189.778Z"
              fill="#fff"
            />
            <path d="M133.072 189.778V144.104L141.288 141.455V189.758H133.072V189.778Z" fill="#fff" />
            <path
              d="M151.76 151.057C150.373 151.057 149.173 150.581 148.159 149.609C147.145 148.657 146.648 147.374 146.648 145.78C146.648 144.187 147.145 142.924 148.159 141.972C149.173 141.02 150.373 140.544 151.76 140.544C153.147 140.544 154.347 141.02 155.361 141.972C156.375 142.924 156.872 144.187 156.872 145.78C156.872 147.374 156.375 148.636 155.361 149.609C154.347 150.561 153.147 151.057 151.76 151.057ZM147.642 189.758V158.342L155.858 155.735V189.778H147.642V189.758Z"
              fill="#fff"
            />
            <path
              d="M178.002 190.772C174.628 190.772 171.648 190.047 169.061 188.578C166.474 187.109 164.467 185.06 163.039 182.411C161.611 179.783 160.907 176.699 160.907 173.181C160.907 170.615 161.321 168.255 162.128 166.103C162.935 163.93 164.074 162.067 165.543 160.515C167.012 158.942 168.73 157.721 170.717 156.852C172.704 155.983 174.877 155.548 177.257 155.548C179.843 155.548 182.141 156.045 184.189 157.059C186.218 158.073 187.894 159.46 189.218 161.24C190.543 163.019 191.474 165.13 192.012 167.531C192.55 169.932 192.612 172.56 192.178 175.374H168.606C168.606 177.03 168.978 178.479 169.786 179.72C170.593 180.962 171.731 181.935 173.2 182.639C174.67 183.342 176.387 183.673 178.353 183.673C180.216 183.673 181.996 183.404 183.693 182.866C185.39 182.328 186.983 181.521 188.473 180.424L189.963 187.254C188.97 187.957 187.811 188.578 186.445 189.116C185.1 189.654 183.693 190.047 182.223 190.337C180.754 190.627 179.367 190.772 178.002 190.772ZM169.061 169.083H184.272C184.21 166.972 183.548 165.296 182.286 164.075C181.023 162.854 179.285 162.254 177.05 162.254C174.814 162.254 172.993 162.854 171.545 164.075C170.096 165.296 169.268 166.951 169.061 169.083Z"
              fill="#fff"
            />
            <path
              d="M219.082 190.772C214.674 190.772 210.825 189.778 207.493 187.812C204.161 185.846 201.594 183.052 199.732 179.431C197.869 175.809 196.959 171.566 196.959 166.661C196.959 163.123 197.497 159.894 198.594 156.935C199.69 153.975 201.222 151.409 203.209 149.236C205.195 147.063 207.555 145.387 210.245 144.207C212.956 143.027 215.916 142.427 219.144 142.427C222.083 142.427 224.649 142.8 226.843 143.524C229.036 144.248 230.982 145.304 232.699 146.67L230.464 155.217C229.161 153.892 227.588 152.899 225.767 152.237C223.945 151.575 221.938 151.243 219.744 151.243C217.55 151.243 215.605 151.595 213.867 152.32C212.128 153.044 210.659 154.058 209.438 155.403C208.217 156.748 207.286 158.342 206.644 160.225C206.002 162.109 205.671 164.219 205.671 166.579C205.671 169.807 206.251 172.56 207.41 174.857C208.569 177.154 210.224 178.913 212.356 180.113C214.488 181.334 217.033 181.935 219.993 181.935C222.103 181.935 224.152 181.604 226.18 180.921C228.209 180.238 230.092 179.161 231.872 177.692L233.838 186.26C231.934 187.688 229.761 188.806 227.319 189.592C224.877 190.378 222.124 190.772 219.082 190.772Z"
              fill="#fff"
            />
            <path
              d="M263.018 161.177C261.88 159.149 260.245 157.659 258.134 156.666C256.044 155.693 253.581 155.197 250.746 155.197C248.821 155.197 246.813 155.466 244.785 156.024C242.736 156.583 240.791 157.473 238.908 158.735L240.025 165.42C241.433 164.365 242.964 163.578 244.599 163.061C246.234 162.543 247.869 162.274 249.483 162.274C251.201 162.274 252.608 162.564 253.684 163.102C254.781 163.661 255.588 164.447 256.106 165.441C256.623 166.455 256.871 167.634 256.871 169V169.476C253.767 169.228 251.035 169.249 248.697 169.538C245.965 169.89 243.709 170.532 241.95 171.484C240.191 172.436 238.866 173.657 238.018 175.126C237.169 176.616 236.755 178.272 236.755 180.134C236.755 182.307 237.211 184.191 238.121 185.784C239.032 187.378 240.274 188.599 241.888 189.468C243.502 190.317 245.323 190.751 247.393 190.751C249.607 190.751 251.594 190.358 253.374 189.53C255.133 188.723 256.52 187.543 257.534 186.012V189.758H264.756V168.876C264.756 165.751 264.177 163.185 263.018 161.177ZM256.871 177.216C256.871 178.644 256.54 179.865 255.899 180.879C255.257 181.914 254.367 182.68 253.27 183.218C252.153 183.756 250.911 184.025 249.545 184.025C247.931 184.025 246.648 183.653 245.717 182.908C244.765 182.163 244.289 181.086 244.289 179.7C244.289 178.748 244.599 177.92 245.199 177.216C245.779 176.513 246.669 175.954 247.848 175.561C249.049 175.168 250.518 174.94 252.277 174.919C253.643 174.878 255.154 174.981 256.871 175.23V177.216Z"
              fill="#fff"
            />
            <path
              d="M269.496 189.778V158.363L277.712 155.755V160.826C278.746 158.901 280.03 157.535 281.602 156.728C283.175 155.921 284.852 155.528 286.652 155.528C287.169 155.528 287.687 155.569 288.183 155.631C288.68 155.693 289.156 155.755 289.591 155.838L289.073 165.172C288.453 164.923 287.79 164.737 287.087 164.613C286.383 164.489 285.7 164.406 285.017 164.406C282.906 164.406 281.147 165.089 279.781 166.475C278.415 167.862 277.712 169.787 277.712 172.291V189.799H269.496V189.778Z"
              fill="#fff"
            />
            <path
              d="M307.472 190.772C304.098 190.772 301.118 190.047 298.531 188.578C295.944 187.109 293.937 185.06 292.509 182.411C291.081 179.783 290.377 176.699 290.377 173.181C290.377 170.615 290.791 168.255 291.598 166.103C292.405 163.93 293.544 162.067 295.013 160.515C296.482 158.942 298.2 157.721 300.187 156.852C302.174 155.983 304.347 155.548 306.726 155.548C309.313 155.548 311.611 156.045 313.659 157.059C315.688 158.073 317.364 159.46 318.688 161.24C320.013 163.019 320.944 165.13 321.482 167.531C322.02 169.932 322.082 172.56 321.648 175.374H298.076C298.076 177.03 298.448 178.479 299.255 179.72C300.063 180.962 301.201 181.935 302.67 182.639C304.14 183.342 305.857 183.673 307.823 183.673C309.686 183.673 311.466 183.404 313.163 182.866C314.86 182.328 316.453 181.521 317.943 180.424L319.433 187.254C318.44 187.957 317.281 188.578 315.915 189.116C314.57 189.654 313.163 190.047 311.693 190.337C310.224 190.627 308.837 190.772 307.472 190.772ZM298.531 169.083H313.742C313.68 166.972 313.018 165.296 311.755 164.075C310.493 162.854 308.755 162.254 306.52 162.254C304.284 162.254 302.463 162.854 301.015 164.075C299.566 165.296 298.738 166.951 298.531 169.083Z"
              fill="#fff"
            />
            <defs>
              <clipPath id="clip0_114_2363">
                <rect width="144" height="144" fill="white" transform="translate(89.5516)" />
              </clipPath>
            </defs>
          </svg>
        </Box>

        <Heading textAlign="center" fontSize={{ base: '3xl', md: '5xl' }} color="white">
          {headingText}
        </Heading>
        <Box mx={'auto'} maxW={'lg'}>
          {children}
        </Box>
      </Box>
    </Flex>
  );
};

export default Hero;
