import { Box, Button, Heading, Spinner, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useUserActions } from '../../hooks';
import QRCode from 'qrcode.react';
import { ModalDefault } from '../generic/ModalDefault';

export const BankIdModal = ({ showModal, setShowModal }: { showModal: boolean; setShowModal: Function }) => {
  const { authnBankId, cancelBankIdAuth } = useUserActions();

  const [confirmWaiting, setConfirmWaiting] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState<null | string>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const login = async (sameDevice: boolean) => {
    await authnBankId(sameDevice, setQrCodeValue, setErrorMessage, handleTimeout);
  };

  const handleTimeout = (sameDevice: boolean) => {
    setQrCodeValue(null);
    if (!sameDevice) {
      login(sameDevice);
    } else {
      cancelBankIdAuth();
      setConfirmWaiting(false);
    }
  };

  const handleSameDeviceLogin = async () => {
    login(true);
    setConfirmWaiting(true);
  };

  const handleOtherDeviceLogin = async () => {
    setConfirmWaiting(true);
    login(false);
  };

  const handleBankidCancel = () => {
    cancelBankIdAuth();
    setQrCodeValue(null);
    setConfirmWaiting(false);
  };

  return (
    <Box>
      <ModalDefault actionTitle="Öppna Bankid" hideFooter={true} onClose={() => setShowModal(false)} isOpen={showModal}>
        {confirmWaiting ? (
          <Box p={12} textAlign="center">
            {qrCodeValue ? (
              <Box display="flex-col" alignItems={'center'} mx="auto">
                <Box display="flex" justifyContent={'center'}>
                  <QRCode value={qrCodeValue} size={175} level="H" />
                </Box>
                <Heading fontSize={'lg'} py={4} as="h2">
                  {errorMessage}
                </Heading>
              </Box>
            ) : (
              <Heading fontSize={'lg'} py={4} as="h2">
                {errorMessage}
              </Heading>
            )}
            <Box>
              <Spinner size={'sm'} />
            </Box>
            <Button
              backgroundColor={'#5E13D8'}
              fontWeight={'light'}
              onClick={handleBankidCancel}
              mt={6}
              colorScheme={'purple'}
            >
              Avbryt
            </Button>
          </Box>
        ) : (
          <Box p={4}>
            <Text fontSize="lg" fontWeight="bold">
              För att du ska kunna boka en tid hos oss behöver du identifiera dig med bankid
            </Text>
            {!confirmWaiting && (
              <Text py="4" fontSize="sm" fontWeight="bold">
                <Text>
                  Genom att logga in accepterar du våra{' '}
                  <Text color="blue" as="span">
                    <a href="./policy/anvandarvillkor.pdf" download="anvandarvillkor">
                      användningsvillkor
                    </a>
                  </Text>{' '}
                  och{' '}
                  <Text color="blue" as="span">
                    <a href="./policy/integritets-policy.pdf" download="integritetspolicy">
                      integritetspolicy
                    </a>
                  </Text>
                </Text>
              </Text>
            )}
            <Box py={4}>
              <Button
                backgroundColor={'#5E13D8'}
                fontWeight={'light'}
                display={{ base: 'block', md: 'none' }}
                colorScheme={'purple'}
                width="100%"
                mb={4}
                onClick={handleSameDeviceLogin}
              >
                Öppna BankID på denna enhet
              </Button>
              <Button
                fontWeight={'light'}
                display={{ base: 'block', md: 'none' }}
                width="100%"
                onClick={handleOtherDeviceLogin}
              >
                BankID på annan enhet
              </Button>
              <Button
                colorScheme={'purple'}
                mb={4}
                backgroundColor={'#5E13D8'}
                fontWeight={'light'}
                display={{ base: 'none', md: 'block' }}
                width="100%"
                onClick={handleOtherDeviceLogin}
              >
                Använd BankID på annan enhet
              </Button>
              <Button display={{ base: 'none', md: 'block' }} width="100%" onClick={handleSameDeviceLogin}>
                Öppna BankID på denna enhet
              </Button>
            </Box>
          </Box>
        )}
      </ModalDefault>
    </Box>
  );
};
