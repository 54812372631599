import { Box, Button, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { formatDateStringWithWeekdayInSwedish, formatTimeString } from '../../helpers/dateFormatting';
import { useAppointmentActions } from '../../hooks';
import { Appointment } from '../../types';
import { SoftCard } from '../generic/SoftCard';

export const NextAvailableAppointmentCard = ({ handleBook, loggedIn }: { handleBook: Function; loggedIn: boolean }) => {
  const { getAppointments, previewAppointmentById } = useAppointmentActions();
  const [nextAvailableTime, setNextAvailableTime] = useState<Appointment | null>(null);

  const doIt = async () => {
    const allAppointments = await getAppointments();
    if (allAppointments) {
      if (allAppointments[0]?.appointments[0]?.id) {
        const nextUpcomingAppointment = await previewAppointmentById(allAppointments[0].appointments[0].id);
        if (nextUpcomingAppointment) {
          setNextAvailableTime(nextUpcomingAppointment);
        }
      } else {
        setNextAvailableTime(null);
      }
    }
  };

  useEffect(() => {
    doIt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <SoftCard backgroundColor={'rgb(255, 255, 255, 0.8)'} mt="4" p={{ base: 8 }}>
        {nextAvailableTime ? (
          <Box
            display={loggedIn ? { base: 'block', md: 'flex' } : 'block'}
            justifyContent="space-between"
            gap={2}
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              {/* <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                rounded="full"
                h="12"
                w="12"
                backgroundColor="purple.100"
              >
                <CalendarIcon id="calendar"></CalendarIcon>
              </Box> */}
              <Box h={{ base: 16, md: 24 }} w={{ base: 16, md: 24 }}>
                <svg viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_b_1_3399)">
                    <rect
                      x="0.5"
                      width="80"
                      height="80"
                      rx="40"
                      fill="white"
                      fillOpacity="0.24"
                      // style={'mix-blend-mode:plus-lighter'}
                    />
                    <g clipPath="url(#clip0_1_3399)">
                      <path
                        d="M53.5209 19.7708H47.8277V18.8139C47.6848 15.0605 42.3042 15.0633 42.1628 18.8139V19.7707H38.8284V18.8139C38.6856 15.0605 33.3051 15.0633 33.1636 18.8139V19.7707H29.8292V18.8139C29.6863 15.0605 24.3058 15.0633 24.1643 18.8139V19.7707H22.7338C21.172 19.7707 19.9014 21.0413 19.9014 22.6031V58.6003C19.9014 59.1182 20.3212 59.5381 20.8392 59.5381H43.3143C44.5573 59.492 44.5564 57.7081 43.3143 57.6624H21.7771V29.2248H50.2148V45.0542C50.2609 46.2972 52.0448 46.2963 52.0905 45.0542V22.6031C52.0905 22.0755 52.5197 21.6464 53.0472 21.6464H53.5209C54.0485 21.6464 54.4777 22.0755 54.4777 22.6031V46.0657C54.5238 47.3088 56.3077 47.3078 56.3534 46.0657V22.6031C56.3534 21.0413 55.0828 19.7708 53.5209 19.7708ZM44.0384 18.8139C44.0866 17.5461 45.9041 17.5471 45.9519 18.8139V19.7707H44.0384V18.8139ZM35.0392 18.8139C35.0874 17.5461 36.9049 17.5471 36.9526 18.8139V19.7707H35.0392V18.8139ZM26.0399 18.8139C26.0881 17.5461 27.9055 17.5471 27.9533 18.8139V19.7707H26.0399V18.8139ZM21.7771 27.349V22.6031C21.7771 22.0755 22.2063 21.6464 22.7338 21.6464H24.1641V22.6031C24.1641 24.1649 25.4347 25.4355 26.9966 25.4355C27.5145 25.4355 27.9344 25.0156 27.9344 24.4977C27.9344 23.9797 27.5145 23.5598 26.9966 23.5598C26.469 23.5598 26.0399 23.1306 26.0399 22.6031V21.6465H33.1635V22.6032C33.1635 24.165 34.4341 25.4356 35.9959 25.4356C36.5139 25.4356 36.9338 25.0157 36.9338 24.4978C36.9338 23.9798 36.5139 23.5599 35.9959 23.5599C35.4684 23.5599 35.0392 23.1307 35.0392 22.6032V21.6465H42.1628V22.6032C42.1628 24.165 43.4334 25.4356 44.9952 25.4356C45.5131 25.4356 45.9331 25.0157 45.9331 24.4978C45.9331 23.9798 45.5131 23.5599 44.9952 23.5599C44.4677 23.5599 44.0385 23.1307 44.0385 22.6032V21.6465H50.3811C50.2736 21.9455 50.2149 22.2676 50.2149 22.6032V27.3491L21.7771 27.349Z"
                        fill="#24084F"
                      />
                      <path
                        d="M35.5413 46.7686C35.15 46.7686 34.7704 46.6288 34.4725 46.375C34.0781 46.0389 33.4862 46.0863 33.1504 46.4806C32.1845 47.7949 34.5158 48.6888 35.5413 48.6442C37.4843 48.6442 39.0649 47.0636 39.0649 45.1206C39.0912 42.9093 36.956 41.1883 34.8022 41.6756V40.1187H37.6138C38.8568 40.0726 38.8559 38.2887 37.6138 38.243H33.8643C33.3463 38.243 32.9264 38.6629 32.9264 39.1808V43.1525C32.9264 43.5183 33.1391 43.8506 33.4711 44.004C33.8033 44.1573 34.1941 44.1035 34.4726 43.8663C35.5142 42.9552 37.2065 43.7352 37.1892 45.1206C37.1892 46.0293 36.45 46.7686 35.5413 46.7686Z"
                        fill="#2B0A61"
                      />
                      <path
                        d="M46.8897 31.6118H25.1019C24.5839 31.6118 24.1641 32.0317 24.1641 32.5497V54.3374C24.1641 54.8554 24.5839 55.2753 25.1019 55.2753H42.1579C43.4009 55.2291 43.4 53.4452 42.1579 53.3995H26.0398V33.4875H45.9519V46.2552C45.998 47.4982 47.7819 47.4973 47.8276 46.2552V32.5497C47.8276 32.0317 47.4076 31.6118 46.8897 31.6118Z"
                        fill="#2B0A61"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M51.1526 64C56.631 64 61.0992 59.5319 61.0992 54.0534C60.5904 40.8777 41.7129 40.8814 41.2061 54.0535C41.2061 59.5319 45.6742 64 51.1526 64Z"
                        fill="#B6A8F0"
                      />
                      <path
                        d="M48.4076 56.5772C47.4608 56.5765 47.1081 55.3122 47.9377 54.827L50.2153 53.512V48.3698C50.2615 47.1268 52.0454 47.1277 52.0911 48.3698V54.0535C52.0911 54.3886 51.9123 54.6982 51.6221 54.8657L48.8756 56.4514C48.728 56.5366 48.5668 56.5772 48.4076 56.5772Z"
                        fill="#2B0A61"
                      />
                      <path
                        d="M51.1534 61.1486H51.1527C50.6348 61.1486 50.2148 60.7287 50.2148 60.2108C50.2608 58.9679 52.045 58.9686 52.0909 60.2108C52.0909 60.7287 51.6712 61.1486 51.1534 61.1486Z"
                        fill="#2B0A61"
                      />
                      <path
                        d="M44.9955 54.9914C44.4776 54.9914 44.0576 54.5718 44.0576 54.0538V54.0532C44.0576 53.5352 44.4776 53.1153 44.9955 53.1153C46.2384 53.1613 46.2377 54.9454 44.9955 54.9914Z"
                        fill="#2B0A61"
                      />
                      <path
                        d="M57.3099 54.9916C56.792 54.9916 56.3721 54.5718 56.3721 54.0538C56.4179 52.811 58.2018 52.8107 58.2478 54.0531V54.0538C58.2478 54.5718 57.8279 54.9916 57.3099 54.9916Z"
                        fill="#2B0A61"
                      />
                    </g>
                  </g>
                  <defs>
                    <filter
                      id="filter0_b_1_3399"
                      x="-15.5"
                      y="-16"
                      width="112"
                      height="112"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
                      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1_3399" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1_3399" result="shape" />
                    </filter>
                    <clipPath id="clip0_1_3399">
                      <rect width="48" height="48" fill="white" transform="translate(16.5 16)" />
                    </clipPath>
                  </defs>
                </svg>
              </Box>

              <Box textColor="#24084f" ml="4">
                <Text>Nästa lediga tid</Text>
                <Text>{nextAvailableTime.description}</Text>
                <Text fontSize={{ base: 'md', md: 'xl' }}>
                  {formatDateStringWithWeekdayInSwedish(nextAvailableTime.start_time)} kl.{' '}
                  {formatTimeString(nextAvailableTime.start_time)}
                </Text>
                {/* <Text color="gray" textTransform={'capitalize'}>
                  {nextAvailableTime?.therapist?.first_name?.toLowerCase() +
                    ' ' +
                    nextAvailableTime?.therapist?.last_name?.toLowerCase()}
                </Text> */}
              </Box>
            </Box>
            {loggedIn ? (
              <Box mt={{ base: '4', md: '0' }}>
                <Button
                  py={6}
                  colorScheme={'purple'}
                  backgroundColor={'#5E13D8'}
                  w="full"
                  fontWeight={'light'}
                  onClick={() => handleBook(nextAvailableTime.id)}
                >
                  <Box as="span">Boka</Box>
                </Button>
              </Box>
            ) : (
              <Box mt={{ base: '4' }}>
                <Button
                  py={6}
                  colorScheme={'purple'}
                  backgroundColor={'#5E13D8'}
                  fontWeight={'light'}
                  w="full"
                  onClick={() => handleBook(nextAvailableTime.id)}
                >
                  <Box as="span" mr="2">
                    Logga in och boka tid
                  </Box>
                  <FaArrowRight />
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <Spinner mx="auto" />
        )}
      </SoftCard>
    </div>
  );
};
