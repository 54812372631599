import { Box, Text, Link, Stack, useBreakpointValue, StackDirection } from '@chakra-ui/react';

export const Footer = ({ show }: { show?: boolean }) => {
  const direction = useBreakpointValue({ base: 'column', md: 'row' }) as StackDirection | undefined;

  return show ? (
    <Box py={24} backgroundColor="purple.900">
      <Stack
        mx={'auto'}
        maxWidth={1024}
        direction={direction}
        textColor="white"
        px="6"
        py="16"
        justifyContent="space-between"
        width="100%"
        spacing={6}
      >
        <Box display="flex" flexDirection="column" justifyContent={{ base: 'center', md: 'space-between' }}>
          {/* <Text fontSize="2xl" pb="2">
          CharlieCare
        </Text> */}
          <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_114_2361)">
              <path
                d="M140.14 59.1446C139.545 59.0254 131.42 57.4768 120.723 58.6681C120.651 48.3517 118.388 40.9659 118.269 40.6085L117.149 37.0824L113.528 37.7733C113.027 37.8686 102.782 39.8937 91.7989 46.7078C85.6043 30.6972 75.0735 22.811 74.4064 22.3345L71.9524 20.5476L69.4984 22.3345C68.8551 22.811 58.3005 30.6972 52.1059 46.7078C41.1463 39.9176 30.8776 37.8686 30.4011 37.7733L26.7797 37.0824L25.6837 40.6085C25.5646 40.9659 23.3012 48.3279 23.2297 58.6681C12.5321 57.4768 4.40772 59.0254 3.81209 59.1446L-0.0476074 59.907L0.500374 63.7905C0.595675 64.3861 2.6923 78.3954 12.5321 92.5C21.6572 105.604 39.3832 121.209 71.9524 121.209C104.522 121.209 122.248 105.604 131.373 92.5C141.213 78.3954 143.309 64.3861 143.381 63.7905L143.929 59.907L140.069 59.1446H140.14ZM111.264 46.946C111.86 49.9957 112.503 54.6416 112.432 60.0976C107.119 61.3603 101.52 63.4331 96.1112 66.6734C95.8492 62.4563 95.2297 58.5251 94.372 54.9275C100.829 50.5913 107.309 48.1611 111.264 46.946ZM32.7122 46.946C36.6433 48.1611 43.1238 50.5675 49.6043 54.9275C48.7466 58.5251 48.1271 62.4563 47.865 66.6734C42.4567 63.4331 36.8578 61.3603 31.5447 60.0976C31.4732 54.6416 32.1165 49.9718 32.7122 46.9222V46.946ZM19.5606 88.0446C13.7949 79.8487 10.9597 71.5575 9.67311 66.7687C17.1304 66.173 33.0934 66.2445 46.3879 75.6078C57.3952 83.3272 64.4474 95.8593 67.4256 112.847C46.507 111.798 30.4011 103.483 19.5368 88.0685L19.5606 88.0446ZM56.0371 72.7012C55.5606 49.5906 66.8538 36.0817 72.0001 31.1737C77.1463 36.1055 88.4395 49.5906 87.963 72.7012C80.9107 79.0387 75.5738 87.306 72.0001 97.408C68.4263 87.306 63.0894 79.0387 56.0371 72.7012ZM124.44 88.0446C113.575 103.46 97.4693 111.798 76.5507 112.823C79.5288 95.8355 86.605 83.3272 97.5884 75.584C108.286 68.079 120.675 66.5304 128.99 66.5304C131.039 66.5304 132.826 66.6257 134.303 66.7448C133.017 71.5575 130.181 79.8487 124.416 88.0208L124.44 88.0446Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_114_2361">
                <rect width="144" height="144" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <Text>© 2023 Charlie Care AB</Text>
        </Box>
        <Box>
          <Text fontSize="2xl" pb="2">
            Kontakt
          </Text>
          <Text>Har du några frågor till oss?</Text>
          <Link href="mailto:info@charliecare.se">info@charliecare.se</Link>
          <Text>
            <Link href="https://www.facebook.com/profile.php?id=61550980539751">Facebook</Link>
          </Text>
          <Text>
            <Link href="https://www.instagram.com/charliecare.se/">Instagram</Link>
          </Text>
          <Text>
            <Link href="https://www.linkedin.com/company/99997778/admin/feed/posts/">LinkedIn</Link>
          </Text>
        </Box>
        <Box>
          <Text fontSize="2xl" pb="2">
            Länkar
          </Text>
          <Text>
            <Link href="#top">Nästa lediga tid</Link>
          </Text>
          <Text>
            <Link href="/about">Om oss</Link>
          </Text>
          <Text>
            <Link href="/work-with-us">Jobba med oss</Link>
          </Text>
          <Text>
            <Link href="#whatCanWeDo">Vad kan du få hjälp med</Link>
          </Text>
          <Text>
            <Link href="#ourTherapists">Våra sexologer</Link>
          </Text>
          <Text>
            <Link href="#howItWorks">Så fungerar det</Link>
          </Text>
        </Box>
        <Box>
          <Text fontSize="2xl" pb="2">
            Policys
          </Text>
          <Text>
            <a href="./policy/integritets-policy.pdf" download="integritetspolicy">
              Integritetspolicy
            </a>
          </Text>
          <Text>
            <a href="./policy/anvandarvillkor.pdf" download="anvandarvillkor">
              Användningsvillkor
            </a>
          </Text>
        </Box>
      </Stack>
    </Box>
  ) : (
    <></>
  );
};
